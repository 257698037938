#scf-root {
	.header {
		width: 100%;
		color: #000;
		line-height: 1.5;
		padding: 10px 10px 1px 10px;
		user-select: none;
	  cursor: move;

		h2 {
			font-size: 15px;
			font-weight: 700;
			text-align: center;
			margin-bottom: .2em;
			line-height: 1.2;
		}

		.resize-btn {
			display:none;
			position: absolute;
			right: 15px;
			top: 5px;
			width: 20px;
			height: 20px;
			cursor: pointer;

			&:after {
				content: "";
				position: absolute;
				top: 9px;
				left: 0;
				width: 100%;
				height: 3px;
				border-radius: 3px;
				background-color: $green;
			}
		}
	}
}