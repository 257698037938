.radios {
	display: flex;
	margin: 10px 0;
	flex-wrap: wrap;
	justify-content: space-between;
}

.radio {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 11px 10px 9px;
	margin-right: 0;
	margin-bottom: 7px;
	width: 48%;
	font-size: 11px;
	font-weight: 400;
	color: $green;
	line-height: 1;
	text-align: center;
	border-radius: 36px;
	cursor: pointer;
	border: 2px solid $green;
	transition: border .1s $QuadEaseOut;

	span {
		display: block;
		width: 100%;
		text-align: center;
	}

	&.active {
		color: #fff;
		background-color: $green;

		&:before {
			border: 2px solid #fff;
			background-color: #7590bb;
		}

		&:after {
			transform: scale(1);
		}

		&:hover {
			border: 2px solid $green;
		}
	}

	&:hover {
		border: 2px solid $green;
	}

	&.disabled {
		color: $gray;
		border-color: $gray;
		cursor: default;
	}
}

.radios-text-top {
	font-weight: 700;
	font-size: 14px;
	display: block;
	margin: 0 0 8px;
}