.summary-page {

	.page-inner {
		padding-left: 3.5%;
		padding-right: 3.5%;
	}

  .download-calculation {
    h2 {
      margin: 20px 0 20px 4%;
    }
  }

	.car-price-heading {
		padding: 0 4%;
    
    span {
      font-size: 1.2em;
    }
	}

	.product-summary {
    position: relative;
    padding: 30px 4%;
    background-color: #fff;
    color: $green;

    h3 {
      margin: 0;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $green;
      font-size: 20px;
    }

    .desc {
      position: absolute;
      top: 40px;
      right: 35px;
      font-size: 14px;
    }

    .product-config {
      display: flex;

      .product-config-cell {
        width: 33%;
        padding: 15px 3% 15px 3%;
        border-left: 1px solid $green;

        &-monthly {
          padding-left: 0;
          border: 0;

          &:first-letter {
            text-transform: uppercase;
          }
        }

        .cell-title {
          display: block;
          margin-bottom: 0.4em;
          font-size: 1.2em;
        }

        .cell-value {
          display: block;
          font-weight: 700;
          font-size: 2.5em;

          .units {
            font-size: 0.6em;
          }
        }
      }
    }
  }

  .product-details {
    margin: 25px 0 10px;
    padding-bottom: 15px;
    background-color: #f6f6f6;
    background-color: #fff;

    .disclaimer {
      margin: 10px 0 10px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 0 0 10px;
        margin: 0 0 10px;
        font-size: 1.2em;
        color: $gray;
        width: 48%;
        border-bottom: 1px solid #c6c6c6;

        &.no-border {
          border: 0;
        }
      }
    }
  }

  .disclaimer {
  	margin: 10px 0 20px;
    font-size: 11px;
    line-height: 1.3;
    color: $gray;

    a {
      text-decoration: underline;
      color: $gray;

      &:hover {
        color: #002c63;
      }
    }
  }

  .long-btn {
  	width: 100%;
  }

  .financial-advisor {
    margin: 25px 0 20px;
    width: 100%;
    padding: 20px;
    color: #01925c;
    background-color: #fff;
    font-size: 20px;

    span {
      font-size: 0.6em;
    }
  }
}