// ------------------------------------------------
// Site Name: 
// Author: 
// ------------------------------------------------

// Import
// ---------------------------

@font-face {
  font-family: 'BNPSans';
  src: url('/fonts/bnpp-sans.ttf') format('trutype'),
  		 url('/fonts/bnpp-sans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BNPSans';
  src: url('/fonts/bnpp-sans-bold.ttf') format('trutype'), 
  		 url('/fonts/bnpp-sans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'BNPSans';
  src: url('/fonts/bnpp-sans-extrabold.ttf') format('trutype'), 
  	   url('/fonts/bnpp-sans-extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'BNPSans';
  src: url('/fonts/bnpp-sans-light.ttf') format('trutype'), 
       url('/fonts/bnpp-sans-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

$bnp-sans: 'BNPSans', sans-serif;