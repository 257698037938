
	.car-price-heading {
		display: flex;
		align-items: center;

		&--text {
			margin-top: 3px;
		}

		span {
			font-size: 1.2em;
		}
	}

	.monthly-fee {
		position: relative;
		padding: 15px 0 5px;
		margin: 15px 0 15px;
		background-color: #fff;
		border-radius: 5px;
		text-align: center;

		.sub-title {
			color: #aaa;
			font-size: 16px;
			text-align: center;
		}

		.price-wrap {
			position: relative;
			
			.i-icon {
				left: calc(50% + 60px);
			}
		}

		.scf-price {
			font-size: 22px;
			font-weight: 700;
			color: #000;
		}

		.credit-disclaimer {
			margin-top: 10px;
		}

		.rrso {
			position: relative;
			font-size: 24px;
			font-weight: 700;
			color: #000;
		}

		.monthly-loader {
			position: absolute;
			top: 4px;
			right: 4px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border-bottom: 3px solid #d3d3d3;
			border-left: 3px solid $green;
			border-right: 3px solid $green;
			border-top: 3px solid #d3d3d3;
			animation: spin 1.2s linear infinite;
		}
	}

	.financing-options {
		margin-bottom: 25px;

	  .option {
	    margin: 0 0 15px;
	    padding: 0px 0;

	    &:nth-child(2) {
	    	//margin: 0 0 5px;
	    }

	    .option-title {
	    	margin: 0;
	    	font-size: 12px;
				font-weight: 300;
				color: #000;

				span {
					font-size: 14px;
					font-weight: 400;
				}
	    }

	    .radios {
	    	margin-bottom: 0;
	    }
	  }
	}

	.fee-title {
		color: #aaa;
	}

	.i-icon {
		position: absolute;
		left: calc(50% + 70px);
		top: 2px;
		width: 16px;
		height: 16px;
		border: 1px solid #aaa;
		text-align: center;
		font-size: 13px;
		line-height: 16px;
		cursor: pointer;
		color: #aaa;
		font-weight: 500;
		border-radius: 50%;
	}
