.toasts {
    position: fixed;
    top: 0;
    right: 15px;
    padding-top: 54px;
    width: 200px;

    .single-toast {
      position: relative;
      width: 200px;
      margin-top: 15px;
      padding: 12px 15px;
      border-radius: 3px;
      background-color: #1e1e1e;
      box-shadow: 0 2px 10px 0 rgba(0,0,0, 0.15);
      font-size: 10px;
      font-weight: 400;
      color: $text-color;
    }
}

 .toast-enter {
   opacity: 0.01;
 }

 .toast-enter.toast-enter-active {
   opacity: 1;
   transition: opacity 400ms $QuadEaseOut;
 }

.toast-exit {
   opacity: 1;
 }

.toast-exit.toast-exit-active {
   opacity: 0.01;
   transition: opacity 200ms ease-in;
 }