.range-slider {
  position: relative;
  height: 16px;
	margin: 18px 5px 0px;
  width: calc(100% - 10px);

  .handle {
  	position: absolute;
    margin-left: -7px;
    margin-top: 1px;
    z-index: 3;
    width: 14px;
    height: 14px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid $green;
  }

  .track {
  	position: absolute;
    top: 7px;
    height: 2px;
    z-index: 2;
    background-color: $green;
    cursor: pointer;
  }

  .rail {
  	position: absolute;
  	z-index: 1;
  	top: 7px;
	  width: 100%;
	  height: 16px;
	  cursor: pointer;
	  border-radius: 3px;
	  background-color: #f4f5f6;

    &-inner {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 2px;
    	background-color: #d8d8d8;
    }

    &:before {
    	content: "";
    	position: absolute;
    	top: -2px;
    	left: -2px;
    	width: 6px;
    	height: 6px;
    	background-color: $green;
    	border-radius: 50%;
    }

    &:after {
    	content: "";
    	position: absolute;
    	top: -2px;
    	right: -5px;
    	width: 6px;
    	height: 6px;
    	background-color: #d8d8d8;
    	border-radius: 50%;
    }
  }

  .min-value {
  	position: absolute;
  	left: -5px;
  	top: -15px;
  	font-size: 10px;
  	color: #000;
  }

  .max-value {
  	position: absolute;
  	right: -5px;
  	top: -15px;
  	font-size: 10px;
  	color: #000;
  }

  .dots {
    position: relative;

    .dot {
      position: absolute;
      top: -10px;
      width: 22px;
      height: 22px;
      margin-left: -11px;
      border-radius: 50%;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 6px;
        height: 6px;
        background-color: #d8d8d8;
        border-radius: 50%;
      }

      &.active:after {
        background-color: $green;
      }
    }
  }
}